<template>
  <div id="layer">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <div v-if="layerEdited" class="vx-row w-full">
        <div class="vx-col w-1/3">
          <vs-input class="mt-2" :label="$t('layer_id')" v-model="layerEdited[0].layer_id" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('layer_key')" v-model="layerEdited[0].layer_key" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('layer_server')" v-model="layerEdited[0].layer_server" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('layer_type')" v-model="layerEdited[0].layer_type" :disabled="true"/>
          <vs-input class="mt-2" :label="($t('layer_size'))" :value="parseFloat(layerEdited[0].layer_size) | prettyBytes" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('conversion_start_date')" v-model="layerEdited[0].conversion_start_date" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('conversion_end_date')" v-model="layerEdited[0].conversion_end_date" :disabled="true"/>
        </div>
        <div class="vx-col w-1/3">
          <vs-input class="mt-2" :label="$t('layer_name')" v-model="layerEdited[0].layer_name"/>
          <vs-input class="mt-2" :label="$t('uploaded_filename')" v-model="layerEdited[0].uploaded_filename" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('user_email')" v-model="layerEdited[0].user_email" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('upload_date')" v-model="layerEdited[0].upload_date" :disabled="true"/>
          <label class="mt-2 vs-input--label">{{$t('layer_movable')}}</label>
          <vs-switch class="mt-2" :label="$t('layer_movable')" v-model="layerEdited[0].layer_movable" :disabled="true"/>
          <label class="mt-2 vs-input--label">{{$t('layer_deformable')}}</label>
          <vs-switch class="mt-2" :label="$t('layer_deformable')" v-model="layerEdited[0].layer_deformable" :disabled="true"/>
        </div>
        <div class="vx-col w-1/3">
          <vs-button class="w-full" @click="dispatchUpdates" :disabled="!diffsToApiQuery">{{this.$t('Save')}}</vs-button>
          <!-- <pre>
            {{diffsToApiQuery}}
          </pre> -->
          <p class="mt-10">{{$t('debug')}}</p>
          <vs-button class="block my-2" color="grey" block :href="layerPreviewUrl" target="_blank">
            {{$t('previewLayer')}}
          </vs-button>
          <vs-button class="block my-2" color="grey" block :href="layerTestFileUrl" target="_blank">
            {{$t('viewLayerInitFile')}}
          </vs-button>
          <vs-button class="block my-2" color="grey" block :href="layerConverterLogUrl" target="_blank">
            {{$t('downloadConversionLogFile')}}
          </vs-button>
          <pre>{{this.converterLog}}</pre>
          <vs-button class="block my-2" color="grey" block @click="dlSrcFiles">
            {{$t('downloadSourceFiles')}}
          </vs-button>
        </div>
      </div>
      <div class="mt-6">
        <h3>{{$t('projectsAllocations')}}</h3>
        <div class="flex vx-row w-full" v-if="((layer[0].project_id) != null)">
          <div class="vx-col w-1/3" v-for="(layer, index) in layer" :key="index">
            <vx-card class="my-6">
              <h4 class="mb-2">{{decodeURIComponent(layer.project_nicename)}}</h4>
              <div class="flex">
                <vs-button class="m-4 block" color="grey" block :href="`/project/${layer.project_slug}`" target="_blank">
                  {{$t('viewProject')}}
                </vs-button>
                <vs-button class="m-4 block" color="danger" block @click="deleteProjectlayerAllocation(layer.project_id, layer.layer_id)" target="_blank">
                  {{$t('deleteLayer')}}
                </vs-button>
              </div>
            </vx-card>
          </div>
        </div>
        <vs-alert class="mt-4" color="warning" v-else>
          {{ $t('noAllocation') }}
        </vs-alert>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import { getDiff } from 'recursive-diff'

export default {
  components: {
  },
  data () {
    return {
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      apiRoute: '/admin',
      loaded: true,
      layer_key: null, 
      layer: null,
      layerEdited: null,
      layerHttpStatus: null,
      sourceFiles: null,
      converterLog: null
    }
  },
  methods: {
    deleteProjectlayerAllocation (projectId, layerId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('deleteProjectLayerText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          params.set('project_id', projectId)
          params.set('layer_id', layerId)
          params.set('context', JSON.stringify({services_user_id: this.$store.state.AppActiveUser.user_id, services_user_hash: this.$store.state.AppActiveUser.user_hash}))
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/layer/deleteProjectLayer`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    },
    dispatchUpdates () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('dispatchLayerUpdatesText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          for (const [key, value] of Object.entries(this.diffsToApiQuery)) {
            params.set(key, JSON.stringify(value))
          }
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/layer/updateLayerSettings`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    }, 
    getEntityData () {
      const params = `layer_key=${this.layer_key}`
      const rimnat_api_call_url = `${this.api_server_baseurl}${this.apiRoute}/layer/getLayer?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          this.loaded = true
          this.layer = response.data
          this.layerEdited = JSON.parse(JSON.stringify(this.layer))
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    },
    getLayerInputFilesList () {
      axios
        .get(this.layerSourceFilesListUrl)
        .then((response) => {
          const data = response.data.split('\n')
            .filter(String)
            .filter(function (e) { return e !== 'data.conf' })
          this.sourceFiles = data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    dlSrcFiles () {
      for (let i = 0; i < this.sourceFiles.length; i++) {
        const layerUrl = `${this.layer[0].layer_url.replace('store', 'stored_inputs')}/${this.sourceFiles[i]}`
        console.log(layerUrl)
        window.open(layerUrl, '_blank').focus()
      }
    },
    refreshAll () {
      this.loaded = false
      this.getEntityData()
    }
  },
 
  computed: {
    layerTestFileUrl () {
      if (!this.layer) return
      const layerType = this.layer[0].layer_type
      const layerUrl = this.layer[0].layer_url
      if (layerType == 'pointcloud') return `${layerUrl}/cloud.js`
      if (layerType == 'structured_pointcloud') return `${layerUrl}/cloud.js`
      if (layerType == 'mesh') return `${layerUrl}/mesh.nxz`
      if (layerType == 'dao') return `${layerUrl}/layers.json`
      if (layerType == 'pano360') return `${layerUrl}/coordinates.txt`
      if (layerType == 'bim') return `${layerUrl}/model.ifc`
    },
    layerSourceFilesListUrl () {
      return `${this.layer[0].layer_url}/input_files.list`
    },
    layerConverterLogUrl () {
      return `${this.layer[0].layer_url.replace('store', 'stored_inputs')}/queue.log`
    },
    layerPreviewUrl () {
      const viewerBaseUrl = this.$appConfig.viewerBaseUrl
      const portalId = this.layer[0].portal 
      const layerKey = this.layer[0].layer_key
      return `${viewerBaseUrl}?portal_id=${portalId}&layer_key=${layerKey}`
    }, 

    diffsToApiQuery () {
      if (this.diffs.length == 0) return 
      return { 
        context: {
          services_user_id: this.$store.state.AppActiveUser.user_id,
          services_user_hash: this.$store.state.AppActiveUser.user_hash
        }, 
        diffs: this.diffsToApi
      }
    },
    diffsToApi () {
      if (this.diffs.length == 0) return 
      const res = {
        layer_key : this.layer_key
      }
      const props = []
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          const prop = e.path[1]
          const val = e.val
          props.push({prop, val})
        }
      })
      res.updates = props
      return res
    },
    diffs () {
      return getDiff(this.layer, this.layerEdited, true)
    }
  },

  beforeMount () {
    this.layer_key = this.$route.params.layer_key
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.getEntityData()
  },

  watch: {
    layerTestFileUrl () {
      // this.getLayerHttpStatus()
      this.getLayerInputFilesList()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
